@import url("https://fonts.googleapis.com/css2?family=UnifrakturCook:wght@700&display=swap");

.navbar {
  position: relative;
  width: 100%;
  height: 6vh;
  background-color: #bfbcd1;
  color: black;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  font-family: "Kirimaru";
  transition: all 1s ease-out;
  /* border: 2px black;
  border-style: none none solid none; */
}

.navbar.fixed-top{
  position: fixed;
  top: 0;
  width: 100%;
  height: 6vh;
  z-index: 3;
}
.navbar img {
  height: 60px;
}

.navbar h2 {
  font-size: 36px;
  margin: 0;
  font-family: "Kirimaru";
}

.navbar h2:hover {
  border-bottom: 1px solid black;
}

.navbar button {
  font-size: 18px;
  text-decoration: none;
  color: black;
  font-family: UnifrakturCook;
  cursor: pointer;
}

.navbar button {
  border: none;
  background: none;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
}

.mobile-navbar {
  top: 0;
  width: 100%;
  height: 6vh;
  background-color: #bfbcd1;
  color: black;
  display: none;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  font-family: "Kirimaru";
}

.mobile-navbar img {
  height: 45px;
}

.mobile-navbar h2 {
  text-align: left;
  font-size: 26px;
  width: fit-content;
  font-family: "Kirimaru";
}

.mobile-navbar h2:hover {
  border-bottom: 1px solid black;
}

.mobile-navbar button {
  display: flex;
  border: none;
  background: none;
  font-size: 18px;
  text-decoration: none;
  color: black;
  font-family: UnifrakturCook;
  cursor: pointer;
  width: 100%;
  padding-left: 10%;
}

.mobile-navbar .fav-icon img{
	height: auto;
	width: 20px;
  }

  /* .mobile-navbar .fav-number p{
    height: 10px;
    width: 10px;
    font-size: 9px;
    top: 0;
    margin: 0;
  } */

.fav-icon {
  position: absolute;
  right: 20px;
}
.fav-icon img{
	height: auto;
	width: 30px;
  }
.fav-number{
  position: relative;
}
.fav-number p {
  z-index: 2;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: white;
  background-color: red;
  position: absolute;
  left: 50%;
  top: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px 0 0 5px;
}

.fav-slide-container{
  display: block;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(100%);
  transition: 0.5s ease-in-out;
  z-index: 3;
  height: 100vh;
}

.fav-blank-space {
  display: flex;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 3;
  width: 75%;
}

.fav-buttons {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background: #bfbcd1;
  margin-left: 5%;
  width: 25%;
  height: 100vh;
  z-index: 3;
  align-items: center;
  
}

.mobile-links {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10vh 0;
  height: inherit;
  justify-content: flex-start;
  align-items: center;
}

.sticky-mobile {
  display: flex;
  position: absolute;
  top: 0;
  z-index: 3;
  overflow-y: hidden;
}

.close-icon{
  display: flex;
  position: absolute;
  top: 4vh;
  left: 30px;
  width: 50px;
  height: auto;
  z-index: 3;
}
.close-fav-icon{
  display: flex;
  position: absolute;
  top: 4vh;
  right: -15px;
  width: 50px;
  height: auto;
  z-index: 3;
}

.opened, .opened-fav{
  transform: translateX(70%);
}

#favHeart{
  font-size: 2.5rem;
}

.fav-slide-wrapper {
  padding-right: 1.5%;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@media (max-width: 875px) {
  .mobile-navbar {
    display: flex;
    justify-content: space-between;
    max-width: 100vw;

  }
  .mobile-navbar.fixed-top{
    position: fixed;
    top: 0;
    width: 100%;
    height: 6vh;
    z-index: 3;
  }
  .mobile-buttons {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    background: #bfbcd1;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3;
    align-items: center;
    transform: translateX(-100%);
    transition: 0.5s ease-in-out;
  }
  .fav-slide-wrapper {
    padding-right: 3%;
  }

  .fav-slide-container{
    display: block;
    flex-direction: row;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    transform: translateX(100%);
    transition: 0.5s ease-in-out;
    z-index: 3;
    height: 100vh;
  }

  .fav-blank-space {
    display: flex;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 3;
    width: 20%;
    background-color: red;
  }
  
  .fav-buttons {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    background: #bfbcd1;
    margin-left: 5%;
    width: 75%;
    height: 100vh;
    z-index: 3;
    align-items: center;
    
  }

  .mobile-links {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10vh 0;
    height: inherit;
    justify-content: flex-start;
    align-items: center;
}

  .sticky-mobile {
    display: flex;
    position: absolute;
    top: 0;
    z-index: 3;
    overflow-y: hidden;
  }

  .close-icon{
    display: flex;
    position: absolute;
    top: 4vh;
    left: 30px;
    width: 50px;
    height: auto;
    z-index: 3;
  }
  .close-fav-icon{
    display: flex;
    position: absolute;
    top: 4vh;
    right: -15px;
    width: 50px;
    height: auto;
    z-index: 3;
  }
  .navbar {
    display: none;
  }
  .opened-fav{
    transform: translateX(20%);
  }
  .opened {
    transform: translateX(0);
  }
  .fav-number p{
    height: 15px;
    width: 15px;
    font-size: 11px;
    top: 3%;
    right: 2%;
    margin: 0;
  }

  .fav-number{
    position: relative;
    top: -0;
    right: -6px;
  }
  #favHeart {
    font-size: 1.8rem;
  }

}
