@import url("https://fonts.googleapis.com/css2?family=UnifrakturCook:wght@700&display=swap");

.main-img_text{
    position: relative;
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 20px;
}

.main-img_text::before{
    content: "";
    background-image: url("../images/Home/Bannerfinalfinal.jpeg");
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
   /* opacity: 0.65; */
    
    /* display: flex;
    width: 100%;
    height: 40vh;
    justify-content: center;
    align-items: center; */
    /* z-index: 2; */
   
    
}
.main-img_text h1, h2, h3 {
    position: relative;
    font-family: "Kirimaru";
}
.main-img_text h1{
    font-size: 108px;
    /* filter: opacity(1); */
}

.main-img_text strong{
    font-family: UnifrakturCook;
}


.main-img_text h2{
    font-size: 44px;
}

.main-img_text h3{
    font-size: 33px;
}

.banner-text {
    text-shadow: -1px -1px 0 #bfbcd1, 1px -1px 0 #bfbcd1, -1px 2px 0 #bfbcd1, 1px 0px 0 #bfbcd1;
    text-align: center;
}

@media screen and (max-width: 875px) {
    .main-img img {
        height: auto;
        width: 100vw;
    }
    .main-img_text{
        top: 8%;
        line-height: 20px;
        background-size: cover;
        height: 25vh;
    }
    
    .main-img_text h1{
        font-size: 30px;
    }
    
    .main-img_text strong {
        font-size: 35px;
    }
    
    .main-img_text h2{
        font-size: 15px;
    }
    
    .main-img_text h3{
        font-size: 10px;
    }
    
}