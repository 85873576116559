.pageNotFound {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 67vh;
    width: 100%;
    font-family: "Kirimaru";
}

.pageNotFound h1{
    width: 50%;
}
