.adminTable__edit-button{
    background-color: sandybrown;
    border-radius: 30%;
}

.adminTable__edit-button a{
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.adminTable__delete-button{
    background-color: rgb(168, 47, 72);
    border-radius: 30%;
    
}

.adminTable__delete-button button{
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
}

.adminStories{
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    padding: 10px 0;
}
.adminStories > .videodiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adminStories > .videodiv video, .adminStories > .videodiv img{
    max-height: 400px;
    max-width: 280px;

}

.adminStoriesForm {
    display: flex;
    padding: 40px;
    flex-direction: column;
    gap: 15px;
}