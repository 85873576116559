.Measure-wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.zoom {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(50px);
  z-index: 10;
}

.zoom img {
  height: 100%;
  border: 1px solid black;
  position: absolute;
}
.normal-img img {
  width: 90%;
  height: auto;
  border: 1px black;
  border-style: dashed;
}
