.home {
  height: 100%;
  width: auto;
  overflow-x: hidden;
}

.container {
  height: 100%;
  width: auto;
}

.background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("../images/one.jpg"); */
  background-position: center;
  height: 100vh;
  filter: blur(5px);
}

.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.style-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85vh;
  /* max-height: 85vh; */
  padding-bottom: 20px;
  /* backdrop-filter: contrast(0.5); */
}
.section-title {
  position: relative;
  font-family: UnifrakturCook;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 0px 15px black);
  border-radius: 10px 0;
  width: 50vw;
}

.section-title h2 {
  font-size: 32px;
  text-align: center;
}

.section-title h2:before {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background: #000;
  left: 100%;
  top: 40%;
  position: absolute;
}
.section-title h2:after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background: #000;
  right: 100%;
  top: 40%;
  position: absolute;
}

/* Story component */
.videoWrapper {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  max-height: 70vh;
  min-height: 70vh;
  width: 100%;
}
.videodiv {
  position: relative;
  transition: all 400ms ease;
  /* transition-delay: 300ms; */
  z-index: 2;
  /* width: 350px; */
}
.videodiv > video {
  border-radius: 15px;
}
.videodiv > img {
  width: auto;
  border-radius: 15px;
  height: 65vh;
}
.inactiveRight {
  /* visibility: hidden; */
  transform: translateX(130%);
  scale: 0.8;
  padding: 50px 20px 50px 20px;
  position: absolute;
  z-index: 1;
  filter: brightness(0.3);
  cursor: pointer;
}

.inactiveLeft {
  transform: translateX(-130%);
  scale: 0.8;
  padding: 50px 20px 50px 20px;
  position: absolute;
  z-index: 1;
  filter: brightness(0.3);
  cursor: pointer;
}

.inactiveNumber {
  scale: 0.8;
  padding: 50px;
  position: absolute;
  z-index: 1;
  filter: brightness(0.3);
  visibility: hidden;
}

.storyButtons {
  display: flex;
  justify-content: space-around;
  width: 25%;
}

.storyButtons button {
  all: unset;
  cursor: pointer;
}

.storyButtons svg {
  font-size: 2.5rem;
  border: 2px solid black;
  border-radius: 50%;
}

/* End of story component */

.product-info {
  font-family: UnifrakturCook;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.product-info a {
  color: slateblue;
  font-size: 24px;
}

/* Newest Product Style */
.product-carousel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100vw;
  align-items: flex-start;
  justify-content: center;
  background-image: url("../images/Home/Bannerfinalfinal.jpeg");
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.BigImage {
  animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  transition: 1s ease-in-out;
  height: 640px;
}
.BigImage img {
  height: 640px;
}

.BigImageContainer {
  min-width: 25vw;
  display: flex;
    justify-content: center;
}

.LittleImagesWrapper {
  backdrop-filter: contrast();
  height: 640px;
  overflow: auto;
}

.LittleImages img {
  height: 200px;
  max-width: 160px;
  width: auto;
  margin: 5px;
}

.LittleImages {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 2fr));
  width: 305px;
  gap: 5px;
}

.SingleLittleImage {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inactiveImages {
  filter: brightness(0.5);
}

.activeImages {
  filter: brightness(1);
}

/* Popular images component */

.popular-container {
  display: flex;
  flex-direction: column;
  background-color: #8c89a1;
  align-items: center;
  border-radius: 0 0 10% 10%;
}

.popular-wrapper1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60vw;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  height: 350px;
}
.popular-wrapper2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60vw;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  height: 350px;
}

.imgContainer {
  width: 300px;
  height: 350px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid dimgray ;
  border-right: 1px solid dimgray ;
}

.imgContainer a {
  height: 350px;
}

.imgContainer:hover{
  filter: brightness(1.2);
}

.imgContainer .card-body{
  width: 90%;
  top: 70%;
  opacity: 0;
  white-space: pre-wrap;
}

.imgContainer:hover .card-body{
  opacity: 1;
  filter: brightness(1);
}

.popular-grids {
  display: flex;
  height: 350px;
  /* width: 100vw; */
  animation: Loop 30s linear infinite;
  background-color: #8c89a1;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  top: 0px;
  left: 0px;
}

.popular-grids2 {
  border-top: 1px solid dimgray ;
  display: flex;
  height: 350px;
  /* width: 100vw; */
  animation: Loop2 30s linear infinite;
  background-color: #8c89a1;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  top: 0px;
  left: 0px;
}
.popular-grids2:hover,
.popular-grids:hover {
  animation-play-state: paused;
}

.popular-grids img, .popular-grids2 img  {
  height: 350px;
  max-width: 250px;
  margin: 0 10px;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.popular-grids2 a, .popular-grids a {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.wrapper {
  position: relative;
  background: #1f1144;
}

canvas {
  height: 500px;
}
@keyframes fade-in-left {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* //////////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 875px) {
  .product-carousel {
    margin-bottom: 15px;
  }

  .product-info {
    width: auto;
    justify-content: unset;
    align-items: unset;
  }

  .product-info h2 {
    font-size: 18px;
    text-decoration: underline;
    margin: 0;
  }

  .product-info h3 {
    font-size: 16px;
    text-decoration: underline;
    margin: 0;
  }

  .product-carousel{
    width: auto;
  }

  .BigImage {
    height: auto;
    max-width: 90vw;
  }
  .BigImage img {
    height: 60vh;
  }
  .BigImageContainer{
    display: flex;
    justify-content: center;
  }
  .LittleImages {
    flex-basis: 100%;
    display: flex;
    overflow-x: scroll;
    min-height: 120px;
    width: 100%;
  }
  .LittleImages img {
    height: 120px;
  }
  .LittleImagesWrapper{
    width: 100%;
    height: auto;
    backdrop-filter: contrast(0.7);
  }

  .SingleLittleImage{
    height: 150px;
    margin: 10px 0;
  }

  .section-title {
    background: #675f9c;
    width: 100vw;
    margin-top: 20px;
    font-size: 18px;
    padding: 10px 0;
  }
  .section-title h2 {
    margin: 0;
    font-size: 18px;
  }

  .popular-wrapper {
    width: 100vw;
  }

  .popular-wrapper1{
    width: 100vw;
    height: 250px;
  }
  .popular-grids {
    height: 250px;
    /* gap: 3vw; */
    justify-items: center;
    justify-content: flex-start;
    direction: rtl;
    animation: Loop 35s linear infinite;
  }
  .popular-grids:hover{
    animation-play-state: running;
  }
  
  .popular-grids2:hover{
    animation-play-state: running;
  }
  .popular-wrapper2{
    width: 100vw;
    height: 250px;
  }
  .popular-grids2 {
    height: 250px;
    /* gap: 3vw; */
    justify-items: center;
    justify-content: flex-start;
    animation: Loop2 35s linear infinite;
  }

  .imgContainer {
    width: 225px;
    border: 1px solid black;
    height: 250px;
  }
  .imgContainer a{
    height: 250px;
  }
  .imgContainer .card-body{
    opacity: 1;
    padding: 0;
    white-space: pre-wrap;
  }
  .imgContainer:hover{
    filter: brightness(1);
  }
    
  .imgContainer:hover .card-body{
    opacity: 1;
    filter: brightness(1);
  }

  .popular-grids img {
    height: 250px;
    /* max-width: 160px; */
    width: 200px;
    /* width: auto; */
    object-fit: cover;
  }

  .popular-grids2 img {
    height: 250px;
    /* max-width: 160px; */
    /* width: 200px; */
  }

  .style-container {
    width: 100vw;
    align-items: normal;
    padding: 30px 0;

    align-items: center;
  }
  .peitho-style {
    display: flex;
    justify-content: center;
    padding: 15px;
  }
  .peitho-alternativo,
  .peitho-cute {
    margin: 10px;
    padding: 10px;
  }
  .peitho-alternativo h2,
  .peitho-cute h2 {
    font-size: 20px;
  }
  .peitho-alternativo img,
  .peitho-cute img {
    height: 25vh;
  }
  .instagram {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: auto;
    margin: 50px;
    flex-direction: column;
  }

  .instagram img {
    width: 95vw;
    height: auto;
  }

  .icon img {
    height: 30px;
    width: auto;
  }
  .videodiv {
    /* position: relative; */
    transition: all 400ms ease;
    /* transition-delay: 300ms; */
    z-index: 2;
    display: flex;
    justify-content: center;
  }
  .videodiv > video {
    height: 60vh;
    transition: all 400ms ease;
    /* transition-delay: 300ms; */
    z-index: 2;
    border-radius: 15px;
  }
  .videodiv > img {
    height: 60vh;
    transition: all 400ms ease;
    z-index: 2;
    border-radius: 15px;
  }
  .inactiveRight {
    /* visibility: hidden; */
    transform: translateX(100%);
    scale: 0.7;
    padding: 30px;
    position: absolute;
    z-index: 1;
  }

  .inactiveLeft {
    /* visibility: hidden; */
    transform: translateX(-100%);
    scale: 0.7;
    padding: 30px;
    position: absolute;
    z-index: 1;
  }
  .inactiveNumber {
    scale: 0.7;
    padding: 30px;
    position: absolute;
    z-index: 1;
    visibility: hidden;
  }
}

.storyButtons {
  width: 75%;
}

@keyframes Loop {
  0% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes Loop2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
