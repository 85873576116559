@import url("https://fonts.googleapis.com/css2?family=UnifrakturCook:wght@700&display=swap");

.fav-container {
  display: flex;
  min-height: 96vh;
  width: 100%;
  justify-content: center;
  font-family: "Kirimaru";
}

.fav-banner {
  background-image: url("../images/Home/Bannerfinalfinal.jpeg");
  background-size: contain;
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid lightgray;
  margin: 60px 0 30px 0;
  border-bottom: 1px solid lightgray;
}
.fav-banner h1 {
  font-size: 28px;
  /* font-family: UnifrakturCook; */
  text-align: center;
  width: 90vw;
}

.fav-list{
  width: 100%;
  overflow-y: auto;
}

.fav-card {
  /* border: 1px solid #181818; */
  width: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 0 15%;
  margin: 10px;
  align-items: center;
  justify-content: center;
}

.fav-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fav-row {
  background-color: rgb(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid black;
  border-radius: 0 30px;
  width: auto;
  margin: 20px;
}
 
.fav-qty {
  display: flex;
  font-size: 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.fav-name h2 {
  font-size: 30px;
  font-family: UnifrakturCook;
}

.fav-image img {
  width: 100px;
  height: 100px;
}

.fav-price h3 {
  font-size: 40px;
}

.fav-card-wrapper a {
  text-decoration: none;
  color: black;
}

.fav-product-card {
  position: relative;
  z-index: 1;
}

.fav-product-card .card-body {
  visibility: hidden;
}

.fav-product-card:hover .card-body {
  visibility: visible;
}

.fav-card img {
  width: 200px;
  height: 250px;
  max-height: 700px;
  object-fit: cover;
  z-index: 1;
  transition: 0.5s;
  border-radius: 0 30px;
}

.fav-card-body {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 5%;
  backdrop-filter: opacity(0.3);
  background-color: rgb(255, 255, 255, 0.7);
  padding: 0 20px 0 20px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 75%;
  pointer-events: none;
}

.fav-card-body h3,
.fav-card-body h4 {
  margin: 5px;
}

.total-fav {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0;
  width: 85%;
  text-decoration: underline;
  font-weight: bold;
  font-size: 23px;
}

.no-favs {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 50vw; */
  height: 400px;
}

.no-favs-box {
  width: 15vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 33px;
  font-weight: bold;
  text-align: center;
}

.product-grid {
  display: grid;
    width: 30%;
    height: auto;
    grid-template-rows: 1fr 1fr;
}

.row-name {
  text-align: center;
  text-decoration: underline;
  border-bottom: 1px solid;
}

.row-rest {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
}

.fav-card-wrapper{
  border-right: 1px solid;
}

.info {
  padding: 20px;
  background-image: url("../images/Home/Bannerfinalfinal.jpeg");
  background-size: contain;
  border-left: 1px solid black;
}

.info p{
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  padding: unset;
  margin: unset;
}

.copy-wrapper{
  height: 200px;
  margin: 20px;
}
.copy-button > button{
  border: 1px solid black;
    border-radius: 20px;
}

.copy-button{
  margin: 20px 0;
  display: flex;
  justify-content: space-evenly;
}

.bubble {
  position: relative;
    background: #ffffff;
    color: #FFFFFF;
    font-family: Arial;
    /* font-size: 20px; */
    line-height: 22px;
    text-align: left;
    width: auto;
    height: auto;
    border-radius: 10px;
    padding: 15px;
    margin: 20px;
    transform: scale(1);
    transition: 0.5s ease-in-out;
}
.bubble:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #ffffff transparent;
  border-width: 0 10px 10px;
  top: -8px;
  left: 25%;
  margin-left: -10px;
}
.bubble span{
  color: black
}
.not-visible{
  transform: scale(0);
  transition: 0.5s ease-in-out;
  position: relative;
    background: #ffffff;
    color: #FFFFFF;
    font-family: Arial;
    /* font-size: 20px; */
    line-height: 22px;
    text-align: left;
    width: auto;
    height: auto;
    border-radius: 10px;
    padding: 15px;
    margin: 20px;
    transition: 0.5s ease-in-out;
}
.not-visible:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #ffffff transparent;
  border-width: 0 10px 10px;
  top: -8px;
  left: 25%;
  margin-left: -10px;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 875px) {
  .fav-card img {
    width: 100px;
    height: auto;
    max-height: 130px;
    object-fit: cover;
    z-index: 1;
    transform-origin: center;
    transform: none;
    transition: 0.5s;
    border-radius: 0 30px;
  }
  .fav-row {
    margin: 5px;
    width: 80%;
  }
  .fav-card {
    width: 125px;
    margin: 10px;
  }
  .fav-card-wrapper {
    display: flex;
    padding: 0 15px;
    width: 100px;
    justify-content: center;
    justify-items: center;
    height: 100%;
  }
  .fav-product-card .fav-card-body {
    visibility: visible;
    padding: 3px;
  }
  .fav-product-card {
    display: flex;
  }
  .fav-card-body h3 {
    font-size: 10px;
  }
  .fav-card-body h4 {
    font-size: 12px;
  }
  .fav-card-body {
    left: 10%;
    width: 75%;
  }
  .total-fav {
    width: 90%;
    font-size: 18px;
  }
  .product-grid{
    grid-template-rows: 1fr 1.5fr;
  }
  .row-name{
    font-size: 10px;
  }
  .row-rest{
    font-size: 17px;
  }
  .no-favs{
    height: 300px;
  }
  .no-favs-box{
    width: 60vw;
  }
}
