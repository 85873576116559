.homeproducts-container{
    width: 100%;
}

.home-products {
    display: flex;

    flex-direction: column;
}

.home-products img {
    height: 500px;
    width: auto;
}



   @media screen and (max-width: 875px){
    .home-products{
        margin: 0;
        width: 100vw;
    }
    .homeproducts-container{
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
   }