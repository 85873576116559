.cards-container {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cards-container:hover img {
  opacity: 0.5;
}

.cards-container img:hover {
  transform: perspective(800px) rotateY(0deg);
  opacity: 1;
}

.cards-grid {
  grid-gap: 10px;
  display: grid;
  gap: 50px;
  grid-template-columns: auto auto auto;
  justify-content: space-evenly;
  justify-items: center;
  height: 100%;
  /* padding: 10px; */
  width: 100%;
}
.cards-grid a {
  text-decoration: none;
  color: black;
}

.product-card {
  position: relative;
  z-index: 1;
}

.product-card .card-body {
  visibility: hidden;
}

.product-card:hover .card-body {
  visibility: visible;
}

.card img {
  width: 260px;
  height: 380px;
  max-height: 700px;
  object-fit: cover;
  z-index: 1;
  transform-origin: center;
  transform: perspective(800px) rotateY(25deg);
  transition: 0.5s;
}

.card-body {
  z-index: 2;
  position: absolute;
  top: 50%;
  backdrop-filter: opacity(0.3);
  background-color: rgb(255, 255, 255, 0.7);
  padding: 0;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  pointer-events: none;
  width: 100%;
}

.card-body h3,
.card-body h4 {
  margin: 5px;
}

.card:has(.favs-added:hover) img, .card:has(.add-favs:hover) img {
  opacity: 1;
  transform: none;
}

.favs-added {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  position: absolute;
  top: 5%;
  right: 7%;
  /* background-color: white; */
  border-radius: 50%;
  transition: 0.5s ease-in;
  z-index: 2;
  border-radius: 50%;
}

.add-favs {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  position: absolute;
  top:  5%;
  right: 7%;
  /* background-color: white; */
  border-radius: 50%;
  transition: 0.5s ease-in;
  z-index: 2;
  border-radius: 50%;
}
.add-favs img {
  width: 100%;
  height: 100%;
}
.favs-added img {
  width: 100%;
  height: 100%;
}

.card:hover .add-favs img {
  opacity: 1;
  transform: none;
}
.card:hover .favs-added img{
  opacity: 1;
  transform: none;
}

.add-favs button,
.favs-added button {
  all: unset;
  cursor: pointer;
}

.add-favs button:focus,
.favs-added button:focus {
  outline: none;
}

.category-wrapper {
  display: flex;
  width: 100vw;
  flex-direction: row;
  /* align-items: center;*/
}

.category-button {
  margin: 20px 0;
}

.category-button button{
  padding: 5px;
  all: unset;
  cursor: pointer;
  background-image: url("../images/Home/Bannerfinalfinal.jpeg");
  background-size: cover;
  border: 1px solid black;
  width: 100%;
}

.category-button h1{
  font-size: 16px;
  text-align: center;
}

@media screen and (max-width: 875px) {
  .category-wrapper{
    flex-direction: column;
  }
  .card img {
    width: 180px;
    height: auto;
    height: 310px;
    object-fit: cover;
    z-index: 1;
    transform-origin: center;
    transform: none;
    transition: 0.5s;
  }
  .cards-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: auto auto;
    justify-content: center;
    justify-items: center;
    /* height: 100%;
    padding: 10px;
    width: 100%; */
  }

  .add-favs, .favs-added {
    width: 30px;
    height: 30px;
    top: 4px;
    right: 0;
  }

  .add-favs img, .favs-added img {
    width: 30px;
    height: 30px;
  }
  .cards-container:hover img {
    opacity: 1;
  }
  
  .cards-container img:hover {
    transform: none;
    opacity: 1;
  }
  .product-card .card-body {
    visibility: visible;
    padding: 3px;
}
  .card-body h3, h4 {
    font-size: 16px;
  }
  /* .card-body h4 {
    font-size: 12px;
  } */
}
