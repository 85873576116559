@import url("https://fonts.googleapis.com/css2?family=UnifrakturCook:wght@700&display=swap");

.catalog-wrapper {
  height: 100%;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
}

.catalog-wrapper h1 {
  font-family: "Kirimaru";
  font-size: 40px;
}
.CatalogDivider{
  width: 30vw;
  display: flex;
  justify-content: center;
}
.catalog-container {
  display: flex;
  width: 100%;
  min-height: 47vw;
  /* margin: 20px; */
  flex-direction: row;
  align-items: flex-start;
  overflow-x: hidden;
}

.catalog-container .category-bubbles {
  justify-content: center;
  height: auto;
  display: flex;
  /* width: 90vw; */
  margin: 30px 0;
  gap: 30px;
  background-color: transparent;
  justify-content: center;
  flex-direction: column;
}

.container-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 30px;
  margin: 10px;
}

.container-pagination button {
  height: 30px;
  width: 30px;
  all: unset;
  cursor: pointer;
  display: flex;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container-pagination button:disabled,
.container-pagination button[disabled] {
  display: none;
}

.container-pagination svg {
  height: 30px;
  width: 30px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container-pagination h2 {
  font-size: 16px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  width: 20vw;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.banner {
  background-image: url("../images/Home/Bannerfinalfinal.jpeg");
  background-size: contain;
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  margin: 30px 0 30px 0;
}

/* .search-dropdown{} */

.searchbar {
  position: relative;
  /* width: 100%;
  display: flex;
  justify-content: center; */
}
.searchbar input {
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 18px;
  color: #555;
  outline: none;
  width: 90%;
}

.searchbar input:focus {
  border-color: #87929e;
  box-shadow: 0 0.2rem 0.2rem 0.2rem rgba(146, 111, 143, 0.534);
}
#hidden {
  display: none;
}

.dropdown {
  height: 300px;
  border: rgb(182, 231, 182);
  background-color: whitesmoke;
  position: absolute;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  z-index: 3;
  width: 95%;
  padding: 0 3px;
  display: none;
}

.search-dropdown {
  border: 1px solid salmon;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.search-dropdown:hover {
  background-color: rgba(15, 151, 200, 0.2);
}

.search-dropdown p {
  text-align: end;
}

.search-dropdown img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
#ClearBtn {
  position: absolute;
  top: 20%;
  right: 5%;
}

.activePage {
  backdrop-filter: contrast(0.5);
  border-radius: 50%;
  height: 35px;
  width: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 875px) {
  .catalog-wrapper {
    flex-direction: column;
  }
  .catalog-container {
    display: flex;
    width: 100%;
    /* margin: 20px; */
    flex-direction: column;
    align-items: center;
  }
  .CatalogDivider{
    width: auto;
    display: flex;
    justify-content: center;
  }
  /* .catalog-container .category-bubbles {
    display: grid;
    justify-content: center;
    height: auto;
    grid-template-columns: 100px 100px 100px;
    width: 90vw;
    gap: 30px;
    background-color: transparent;
  } */
  .container h1 {
    margin: 0;
  }

  .searchbar {
    position: relative;
  }
  .searchbar input {
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #555;
    outline: none;
  }

  .searchbar input:focus {
    border-color: #87929e;
    box-shadow: 0 0.2rem 0.2rem 0.2rem rgba(146, 111, 143, 0.534);
  }
  #hidden {
    display: none;
  }

  .dropdown {
    height: 200px;
    border: rgb(182, 231, 182);
    background-color: whitesmoke;
    position: absolute;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    width: 95%;
    padding: 0 3px;
    display: flex;
  }



  .search-dropdown {
    border: 1px solid rgba(146, 111, 143, 0.534);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 15px;
  }

  .search-dropdown:hover {
    background-color: rgba(15, 151, 200, 0.2);
  }

  .search-dropdown p {
    text-align: center;
    width: 40%;
    font-size: 18px;
    font-family: "Kirimaru";
    color: black;
    text-decoration: underline black;
    font-weight: 800;
  }

  .search-dropdown img {
    width: 60px;
    height: 60px;
    border-radius: 15%;
    margin: 2px;
  }
  #ClearBtn {
    position: absolute;
    top: 20%;
    right: 5%;
  }

  .pagination-wrapper {
    width: 75vw;
  }
}
