.detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.detail-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  flex-direction: column;
  position: relative;
  margin: 30px 0;
}

.detail-wrapper img {
  height: 100%;
  width: auto;
  transition: all 0.25s ease-in-out;
}

.detail-wrapper h2 {
  text-decoration: underline;
}

.detail-description h3 {
  font-size: 18px;
}

.detail-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 65%;

  height: 100%;
  justify-content: flex-start;
  text-align: left;
  margin: 0%;
}

.detail-container h1 {
  font-size: 28px;
  margin: 0;
  font-weight: 400;
}

.detail-container h2 {
  font-size: 26px;
  margin: 0;
  font-weight: 100;
}

.detail-container p {
  font-size: 16px;
}

.detail-buttons img {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.inactive {
  transition: 0.25s ease-in-out;
  filter: brightness(0.3);
}

.detail-link {
  height: 5vh;
  margin: 4vh 0 2vh 1vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.detail-link button {
  all: unset;
  text-decoration: none;
  color: #c59fc3;
}

.box {
  width: 70vw;
}

.detail-box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: auto;
  height: 70vh;
  margin: 0 1vw 0 1vw;
}

.image-box {
  display: flex;
  width: 50%;
  flex-direction: column-reverse;
  align-items: center;
  background-image: url("../images/Home/Bannerfinalfinal.jpeg");
  background-size: contain;
}

.detail-imgs {
  display: flex;
  /* flex-direction: column; */
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  overflow-x: scroll;
  gap: 1vh;
  /* padding-left: 4px;*/
}

.arrimgs {
  width: 120px;
  display: flex;
  justify-content: center;
  backdrop-filter: contrast(0.5);
}

.arrimgs img {
  cursor: pointer;
  height: 12vh;
}

.prev-img img {
  width: auto;
  max-width: 20vw;
  height: 52vh;
  margin: 0 2vw;
}

.detail-container .favs-added {
  width: 40px;
  height: 40px;
  display: flex;
  position: unset;
  right: 25%;
  top: 3%;
  border-radius: 50%;
  transition: 0.5s ease-in;
  z-index: 2;
  margin: 5% 0;
}

.detail-container .add-favs {
  width: 40px;
  height: 40px;
  display: flex;
  position: unset;
  right: 25%;
  top: 3%;
  border-radius: 50%;
  transition: 0.5s ease-in;
  z-index: 2;
  margin: 5% 0;
}

.card:hover .add-fav img {
  opacity: 1;
  transform: none;
}
.card .add-fav img:hover {
  opacity: 1;
}

.go-back-button button {
  all: unset;
  cursor: pointer;
}
.go-back-button {
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  top: 20px;
  border-radius: 50%;
  transition: 0.5s ease-in;
  z-index: 2;
}

.other-items {
  margin-bottom: 5vh;
  display: flex;
  flex-direction: column;
}

.other-items .card img {
  width: 200px;
  height: 250px;
  transform: none;
}

.other-items .add-favs img,
.other-items .favs-added img {
  width: 40px;
  height: 40px;
  background-color: #ffffff69;
  border-radius: 50%;
}

.other-items .cards-container:hover img {
  opacity: 1;
}

.other-items .cards-container img:hover {
  transform: none;
  opacity: 1;
}

.not-available {
  display: flex;
  position: absolute;
  top: 3%;
  right: 5%;
  background: #fff;
  padding: 7px;
  border: 1px solid black;
  border-radius: 5px;
  font-family: sans-serif;
}

.prev-img {
  animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  transition: 1s ease-in-out;
  height: -webkit-fill-available;
}

.detail-zoom {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(50px);
  z-index: 3;
}

.detail-zoom img {
  width: auto;
  height: 100vh;
  border: 1px solid black;
  position: absolute;

}

@keyframes fade-in-right {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (max-width: 875px) {
  .detail {
    height: 100%;
  }
  .box {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 5vw;
  }
  .detail-box {
    justify-content: unset;
    flex-direction: column;
    align-items: center;
    height: auto;
  }
  .detail-link {
    margin: 4vh 0 2vh 4vw;
  }
  .image-box {
    width: 100%;
  }
  /* .detail-container {
    margin: 0 10% 10% 10%;
  } */
  .prev-img img {
    max-width: 90vw;
    min-width: 200px;
    width: auto;
    min-height: 350px;
    height: 60vh;
    margin: 0 2vw;
  }

  .arrimgs {
    min-width: 90px;
    /* overflow-x: scroll; */
    display: flex;
    justify-content: center;
    -webkit-backdrop-filter: contrast(0.5);
    backdrop-filter: contrast(0.5);
  }

  .arrimgs img {
    /* height: 10vh; */
    min-height: 70px;
    width: auto;
    /* min-width: 75px; */
    /* max-height: 11vh; */
  }
  /* .image-box {
    height: 55vh;
  } */
  .detail-container .add-favs {
    border-radius: 50%;
    right: 6%;
    transition: none;
  }
  .detail-container .favs-added {
    border-radius: 50%;
    right: 6%;
    transition: none;
  }

  .other-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: -webkit-fill-available;
  }

  .other-items .cards-container {
    width: 100%;
  }
  .other-items .cards-grid {
    grid-template-columns: repeat(auto-fit, minmax(100px, 3fr));
  }

  .other-items .card img {
    width: 100px;
    object-fit: cover;
    z-index: 1;
    transform-origin: center;
    transform: none;
    transition: 0.5s;
  }
  .other-items .add-favs img,
  .other-items .favs-added img {
    width: 30px;
    height: 30px;
    background-color: #ffffff69;
    border-radius: 50%;
  }
}
