@import url("https://fonts.googleapis.com/css2?family=UnifrakturCook:wght@700&display=swap");

.footer {
    width: 100%;
    height: 100%;
    padding: 3vh 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    background-color: #bfbcd1;
    justify-content: center;
    align-items: center;
    border-top: 1px solid black;
    position: relative;
}

.quick-links {
    font-family: monospace;
    width: 65vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
}

.quick-links h1{
    font-size: 18px;
}

.quick-links li{
    display: block;
    line-height: 25px;
}
.quick-links a, .quick-links p{
    font-size: 14px;
    color: black;
}
.liwi{
    position: absolute;
    bottom: 0;
    right: 1%;
    font-size: 16px;
    font-family: monospace;
}
.liwi a{
    color: #6c5dbf;
}

@media screen and (max-width: 875px){
    .footer{
        flex-direction: column;
    }
    .quick-links{
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        gap: 25px;
        margin-right: 20%;
    }
    .quick-links ul{
        padding: 0;
        margin: 0;
    }
    .liwi{
        font-size: 12px;
    }
}