.sticky {
    position: sticky;
    top: 0;
    z-index: 5;
}

.aux {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.screen1-in, .screen2-in, .screen3-in, .screen1-out, .screen2-out, .screen3-out{
    height: 100vh;
    width: 100%;
    pointer-events: none;
    z-index: 6;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    overflow-y: hidden;
    overflow-x: hidden;
}

.screen1-in{
    background-color:   #33323a;
    animation: openScreen1 ease-in-out 3s;
    transform: translate(-100%);
}

.screen2-in{
    background-color: #67647a;
    animation: openScreen2 ease-in-out 2s;
    transform: translate(-100%);
}

.screen3-in{
    background-color:  #bfbcd1;
    animation: openScreen3 ease-in-out 1s;
    transform: translate(-100%);
}

.screen1-out{
    background-color: #33323a;
    animation: closeScreen1 ease-in-out 1s;
    transform: translate(0%);
}

.screen2-out{
    background-color: #67647a;
    animation: closeScreen2 ease-in-out 2s;
    transform: translate(0%);
}

.screen3-out{
    background-color:  #bfbcd1;
    animation: closeScreen3 ease-in-out 3s;
    transform: translate(0%);
}

.HomeLoad{
    height: 100vh;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    z-index: 6;
    background-color: #c0bdd3;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* animation: curtain 6s; */
    animation: curtain 7s;
    overflow-y: hidden;
    overflow-x: hidden;
}

.HomeLoad video {
    height: 700px;
    width: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}

.HomeLoad h1 {
    opacity: 0;
    animation: vanish 1s;
    /* animation: vanish 3s; */ 
}

.HomeLoadOut {
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 3;
    background-color: #c0bdd3;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: curtain2 1s;
    /* animation: curtain2 4s; */
    overflow-y: hidden;
    overflow-x: hidden;
}

@keyframes openScreen1 {
    0%{
        transform: translate(0);
    }
    100%{
        transform: translate(100%);
    }
}

@keyframes closeScreen1 {
    0%{
        transform: translate(-100%);
    }
    70%{
        transform: translate(0);
    }
    100%{
        transform: translate(0);
    }
}
@keyframes openScreen2 {
    0%{
        transform: translate(0);
    }
    100%{
        transform: translate(100%);
    }
}

@keyframes closeScreen2 {
    0%{
        transform: translate(-100%);
    }
    70%{
        transform: translate(0);
    }
    100%{
        transform: translate(0);
    }
}
@keyframes openScreen3 {
    0%{
        transform: translate(0);
    }
    100%{
        transform: translate(100%);
    }
}

@keyframes closeScreen3 {
    0%{
        transform: translate(-100%);
    }
    70%{
        transform: translate(0);
    }
    100%{
        transform: translate(0);
    }
}

@keyframes vanish {
    0%{
        opacity: 1;
        pointer-events: all;
    }
    80%{
        opacity: 1;
        pointer-events: all;
    }
    100%{
        opacity: 0;
    }
}

@keyframes curtain {
    0%{
        opacity: 1;
        pointer-events: all;        
    }
    70%{
        opacity: 1;
        pointer-events: all;
    }
    100%{
        opacity: 0;
    }
}

@keyframes curtain2 {
    0%{
        opacity: 0;
    }
    30%{
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}