.home .category-bubbles {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
	/* height: 150px; */
	width: 100%;
	background-color: transparent;
	z-index: 2;
	gap: 40px;
	margin: 20px 0;
	/* padding: 20px 0; */
}
.home .category-bubbles .bubbleGroupWrapper{
	display: flex;
    width: 100%;
    justify-content: space-evenly;
	margin: 20px 0;
}
.bubbleGroupWrapper button{
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
	background-color: #ffffff;
}

.bubbleGroupWrapper div {
	transition: 0.5s ease-in-out;
	justify-content: space-evenly;
	display: flex;
	/* width: 90%; */
    margin: 5px;
}
.bubbleGroupWrapper a {
	text-decoration: none;
}

.bubbleGroupWrapper div:hover {
	transform: scale(1.2);
	transition: 0.5s ease-in-out;
}

.bubbleGroupWrapper img {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	border: 2px solid white;
	box-shadow: 0px 0px 20px 2px;
}

.category-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.category-container span {
	color: black;
	font-size: 18px;
	font-weight: bold;
	font-family: "Kirimaru";
}
.category-bubbles .bubbleGroupWrapper{
	display: grid;
	grid-template-columns: auto auto;
	gap: 30px;
}


@media screen and (max-width: 875px) {

	.home .category-bubbles  .bubbleGroupWrapper{
		display: grid;
		grid-template-columns: auto auto auto;
		height: auto;
		margin: 20px 0;
	}

	.category-bubbles img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		border: 2px solid white;
		box-shadow: 0px 0px 20px 0px;
	}
	.category-container span {
		color: black;
		font-size: 14px;
	}
	.category-bubbles div:hover {
		transform: none;
		transition: none;
	}
	/* .bubbleGroupWrapper{
		display: grid;
		width: 100%;
		grid-template-columns: auto auto auto;
		gap: 15px 0;
	} */
	.category-bubbles .bubbleGroupWrapper{
		display: grid;
		width: 100%;
		grid-template-columns: auto auto auto;
		gap: 20px;
	}
}