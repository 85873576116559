@font-face {
	font-family: "Kirimaru";
	src: local("Kirimaru"),
	url("./kirimaruFont/Kirimaru.woff2") format("woff2");
  }
  
  body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html, body{
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

*{
	scroll-behavior: smooth;
}